<template>
  <van-nav-bar
    :title="title"
    left-arrow
    fixed
    placeholder
    safe-area-inset-top
    @click-left="onBackClick"
  />
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: String,
  },
  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
  },
};
</script>
